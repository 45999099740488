<template>
  <div class="card" style="border: none" id="print-lab-report">
    <div class="card-body">
      <!-- HEADER::START -->
      <div class="row">
        <div class="col-1">
          <img
            :src="config.city_logo"
            style="max-height: 100px; position: relative; top: 6%; left: 11px"
            alt="LOGO KLINIK"
          />
        </div>
        <div class="col text-center" style="padding: 10px 30px">
          <h4 class="text-uppercase" style="font-weight: 600">
            Pemerintah {{ config.city }}
          </h4>
          <h4 class="text-uppercase" style="font-weight: 600">
            Kecamatan {{ config.district }}
          </h4>
          <h3 class="text-uppercase" style="font-weight: bold">
            desa {{ config.village_name }}
          </h3>
          <div>
            <span>{{ config.address }}</span>
            <span> - Kode Pos: {{ config.postal_code }}</span>
          </div>
          <div>
            <span>Website: {{ config.web_url }}</span>
            <span> - Email: {{ config.village_email }}</span>
          </div>
        </div>
      </div>
      <hr style="border-top: 3px solid" class="mt-6" />
      <!-- HEADER::END -->
      <!-- TITLE::START -->
      <div class="d-flex flex-column text-center">
        <h4 class="text-uppercase mb-0">
          <u>{{ data.title }}</u>
        </h4>
        <span class="">Nomor: {{ data.number }}</span>
      </div>
      <!-- TITLE::END -->
      <!-- CONTENT::START -->
      <div class="row justify-content-center mt-4">
        <p class="col-10 mb-0" style="text-align: justify">
          {{ data.opening_sentences }}
        </p>
      </div>
      <div class="row justify-content-center mt-0 mb-0 ml-1">
        <div class="col-10 pt-0">
          <table class="mb-0 mt-1" border="0">
            <tr>
              <td>Nama</td>
              <td>:</td>
              <td>{{ data.citizen_name }}</td>
            </tr>
            <tr>
              <td>NIK</td>
              <td>:</td>
              <td>{{ data.citizen_id_card_number }}</td>
            </tr>
            <tr>
              <td>Jenis Kelamin</td>
              <td>:</td>
              <td>{{ data.citizen_gender_display }}</td>
            </tr>
            <tr>
              <td style="padding-right: 40px">Tempat, Tanggal Lahir</td>
              <td class="pr-4">:</td>
              <td>
                {{ data.citizen_birt_place }}, {{ data.citizen_birt_date }}
              </td>
            </tr>
            <tr>
              <td>Warganegara / Agama</td>
              <td>:</td>
              <td>
                {{ data.citizen_citizenship }} / {{ data.citizen_religion }}
              </td>
            </tr>
            <tr>
              <td>Pekerjaan</td>
              <td>:</td>
              <td>{{ data.citizen_profession }}</td>
            </tr>
            <tr>
              <td>Status Pernikahan</td>
              <td>:</td>
              <td>{{ data.citizen_is_married_display }}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>:</td>
              <td>{{ data.citizen_address }}</td>
            </tr>
            <tr>
              <td>Keperluan</td>
              <td>:</td>
              <td>{{ data.purpose }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row justify-content-center mt-0">
        <p class="col-10 pt-0" style="text-align: justify">
          Nama tersebut diatas benar-benar warga {{ config.village_name }} yang
          sepengetahuan kami berkelakuan baik.
        </p>
      </div>
      <div class="row justify-content-center mt-0">
        <p class="col-10 pt-0" style="text-align: justify">
          {{ data.closing_sentences }}
        </p>
      </div>
      <div class="d-flex justify-content-end" style="margin: 0 10%">
        <div
          class="
            d-flex
            flex-column
            mt-5
            justify-content-center
            col-4
            text-center
            px-0
          "
          style="min-height: 200px"
        >
          <div>
            <div>
              <span class="text-uppercase">{{ config.village_name }}</span
              ><span>, {{ data.registered_date }}</span>
            </div>
            <span class="text-uppercase"
              >KEPALA DESA {{ config.village_name }}</span
            >
          </div>
          <div>
            <vue-qrcode :value="qrcodeValue" :color="{ dark: '#000000ff'}" type="image/png" @change="onDataUrlChange" />
            <img :src="dataUrl" alt="">
          </div>
          <div class="mt-auto">
            <span class="d-block" style="white-space: nowrap; font-weight: 600"
              ><u>{{ config.village_chief_name }}</u></span
            >
            <!-- <span class="d-block">{{data.lab_template_authorizer_position}}</span> -->
          </div>
        </div>
      </div>
      <!-- CONTENT::END -->
    </div>
  </div>
</template>

<script>
import { getConfiguration } from "@/core/services/jwt.service.js";
import VueQrcode from "vue-qrcode";

export default {
  components: {
    VueQrcode,
  },

  props: {
    data: Object,
    height: String,
    setting: Object,
    qrcodeValue: {
      type: String,
      default: "google.com"
    }
  },

  data() {
    return {
      config: getConfiguration(),
      dataUrl: null,
    };
  },

  methods: {
    onDataUrlChange(dataUrl) {
      console.log('change', dataUrl)
      this.dataUrl = dataUrl
    },
    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-lab-report");
    },
  },

  mounted() {
    console.log('route', this.$route)
    console.log('window', window)
  },
};
</script>

<style>
figure.table table {
  width: 100% !important;
}
</style>